<template>
    <section>
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center my-4 bg-white br-8 py-1">
            <div class="col border-right">
                <p class="text-general f-500 f-18">
                    Pedidos pendientes <br /> cambio de estado:
                </p>
            </div>
            <div class="col-1 border-right">
                <p class="f-30 text-general text-center f-600">
                    {{ total_pedidos }}
                </p>
                <p class="text-general f-400 text-center f-14">
                    <i class="icon-pedidos f-17" />
                    Pedidos
                </p>
            </div>
            <div class="col border-right">
                <p class="f-30 text-general text-center f-600">
                    {{ total_intentos }}
                </p>
                <p class="text-general f-400 text-center f-14">
                    <i class="icon-history f-17" />
                    Nuevo intento
                </p>
            </div>
            <div class="col border-right">
                <p class="f-30 text-general text-center f-600">
                    {{ total_productos_faltantes }}
                </p>
                <p class="text-general f-400 text-center f-14">
                    <i class="icon-package-variant f-17" />
                    Pedidos con productos faltantes
                </p>
            </div>
            <div class="col border-right">
                <p class="f-30 text-general text-center f-600">
                    {{ total_productos }}
                </p>
                <p class="text-general f-400 text-center f-14">
                    <i class="icon-package-variant-closed f-17" />
                    Productos
                </p>
            </div>
            <div class="col">
                <p class="f-30 text-general text-center f-600">
                    {{ separadorNumero(total_valor_pedidos) }}
                </p>
                <p class="text-general f-400 text-center f-14">
                    <i class="icon-comision f-12 mr-2" />
                    Valor de los pedidos
                </p>
            </div>
        </div>
        <!-- Space -->
        <div class="row mx-0 py-4" />
        <!-- Space -->
        <div class="row mx-0 pr-3 align-items-center">
            <p class="col-auto text-general f-18 f-500">
                Pedidos pendientes de cambio estado
            </p>
            <div class="col-auto d-middle px-0 ml-auto">
                <i class="icon-history text-general" />
                <span class="text-general2 f-12 f-300 ml-2">Nuevo intento</span>
            </div>
            <div class="col-auto d-middle px-0 ml-3">
                <i class="icon-package-variant f-18 text-general-red" />
                <span class="text-general2 f-12 f-300 ml-2">Productos faltantes</span>
            </div>
            <el-tooltip placement="bottom" content="Filtro" effect="light">
                <div class="btn-action border ml-2 cr-pointer" @click="irFiltro">
                    <i class="icon-filter-outline f-20" />
                </div>
            </el-tooltip>
        </div>
        <!-- Space -->
        <div class="row mx-0 py-4" />
        <!-- Space -->
        <div v-for="(data,x) in pedidos" :key="x">
            <div class="row mx-0 mb-5">
                <p class="col-12 pl-5 text-general f-18 f-500">
                    Entregas programadas para el: {{ data.fecha | helper-fecha('dddd DD MMMM YYYY') }}
                </p>

                <div class="col-12 px-0 bg-white br-8 mt-3">
                    <div v-for="(info,z) in data.items" :key="z">
                        <div class="row mx-0 py-3">
                            <p class="col-auto f-18 f-500 text-general">
                                {{ determinarHorario(info.horario) }}
                            </p>
                            <div class="col-3 px-3 ml-auto f-14">
                                <el-input v-model="info.buscador" placeholder="Buscar" class="br-20 w-100 ml-auto" size="small" />
                            </div>
                        </div>
                        <el-table
                        :data="info.items.filter(data => !info.buscador || data.leechero.toLowerCase().includes(info.buscador.toLowerCase()) || data.vehiculo_conductor.toLowerCase().includes(info.buscador.toLowerCase()))"
                        header-row-class-name="text-general f-16 f-500"
                        style="width: 100%"
                        >
                            <el-table-column
                            fixed
                            class-name="text-center"
                            width="120"
                            >
                                <template slot-scope="scope">
                                    <div class="row mx-0 justify-content-around">
                                        <div class="col px-0">
                                            <el-tooltip v-if="scope.row.nuevo_intento > 1" placement="bottom" content="Nuevo intento de entrega" effect="light">
                                                <i class="icon-history f-20 text-general mx-2" />
                                            </el-tooltip>
                                        </div>
                                        <div class="col px-0">
                                            <el-tooltip v-if="scope.row.productos_faltantes" placement="bottom" content="Productos faltantes" effect="light">
                                                <i class="icon-package-variant f-20 text-general-red mx-2" />
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                            fixed
                            label="Pedido"
                            width="100"
                            >
                                <template slot-scope="scope">
                                    <p>
                                        {{ scope.row.id }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                            label="Vendedor"
                            fixed
                            width="180"
                            class-name="text-center"
                            >
                                <template slot-scope="scope">
                                    <p>
                                        {{ scope.row.leechero }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="cliente"
                            label="Cliente"
                            class-name="text-center"
                            min-width="180"
                            />
                            <el-table-column
                            prop="cantidad_productos"
                            label="Productos"
                            min-width="120"
                            class-name="text-center"
                            />
                            <el-table-column
                            label="Valor"
                            min-width="100"
                            class-name="text-center"
                            >
                                <template slot-scope="scope">
                                    <p>
                                        {{ separadorNumero(scope.row.valor_final_pedido) }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                            prop="vehiculo_placa"
                            label="Vehículo"
                            min-width="100"
                            />
                            <el-table-column
                            prop="vehiculo_conductor"
                            label="Conductor"
                            min-width="120"
                            />
                            <el-table-column
                            prop="estado_leeche"
                            label="Estado App"
                            class-name="text-center"
                            width="180"
                            />
                            <el-table-column
                            prop="estado_logistica"
                            label="Estado logistica"
                            class-name="text-center"
                            width="180"
                            />
                            <el-table-column
                            prop="total_peso"
                            label="Peso (Kg)"
                            class-name="text-center"
                            />
                            <el-table-column
                            prop="total_volumen"
                            label="Volumen (m3)"
                            class-name="text-center"
                            />
                            <el-table-column
                            label=""
                            width="180"
                            class-name="text-center"
                            >
                                <template slot-scope="scope">
                                    <div
                                    class="btn-black btn-general px-3 ml-auto f-14 br-20"
                                    @click="irPedido(scope.row.id)"
                                    >
                                        Ir al pedido
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <modal-filtro ref="modalFiltro" :filtros="info_filtros" @filtrar-pedidos="filtrar" />
        <modal-detalle-pedido ref="modalDetalle" @update="listarPedidos" />
    </section>
</template>

<script>
import Pedidos from "~/services/pedidos/pedidos-routing";
import { mapGetters } from 'vuex'
export default {
    components:{
        modalFiltro: () => import('./partials/modalFiltroFallidos'),
        modalDetallePedido: () => import('./partials/modalDetallesRuta'),
    },
    data(){
        return {
            cargando: true,
            pedidos: [],
            info_filtros: {},
            envios_select: {},
            buscador: '',
            total_pedidos: '',
            total_intentos: '',
            total_productos: '',
            total_valor_pedidos: '',
            total_productos_faltantes: '',
            filtro_leechero: '',
            filtro_estado: '',
            filtro_conductor: '',
            filtro_vehiculo: '',
            filtro_logistico: '',
            filtro_valor_desde: '',
            filtro_valor_hasta: '',
            filtro_productos_desde: '',
            filtro_productos_hasta: '',
            filtro_peso_desde: '',
            filtro_peso_hasta: '',
            filtro_volumen_desde: '',
            filtro_volumen_hasta: '',
        }
    },

    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
    },

    watch: {
        id_moneda(){
            if (!this.cargando){
                this.validar()
            }
        }
    },

    created(){
        this.validar();
    },

    methods: {
        async validar(){
            if (this.id_moneda === null){
                setTimeout(async() => {
                    this.listarPedidos();
                },1000);
            } else {
                this.listarPedidos();
            }
        },

        filtrar(filtros){
            this.filtro_estado = filtros.estado_leeche;
            this.filtro_leechero = filtros.leechero;
            this.filtro_conductor = filtros.conductor;
            this.filtro_vehiculo = filtros.vehiculo;
            this.filtro_logistico = filtros.estado_logistico;
            this.filtro_valor_desde = filtros.valor_desde;
            this.filtro_valor_hasta = filtros.valor_hasta;
            this.filtro_productos_desde = filtros.productos_desde;
            this.filtro_productos_hasta = filtros.productos_hasta;
            this.filtro_peso_desde = filtros.peso_desde;
            this.filtro_peso_hasta = filtros.peso_hasta;
            this.filtro_volumen_desde = filtros.volumen_desde;
            this.filtro_volumen_hasta = filtros.volumen_hasta;
            this.validar();
        },

        async listarPedidos(){
            try {
                let params =  {
                    id_moneda: this.id_moneda,
                    filtro_buscador: this.buscar,
                    filtro_leechero: this.filtro_leechero,
                    filtro_estado: this.filtro_estado,
                    filtro_conductor: this.filtro_conductor,
                    filtro_vehiculo: this.filtro_vehiculo,
                    filtro_logistico: this.filtro_logistico,
                    filtro_valor_desde: this.filtro_valor_desde,
                    filtro_valor_hasta: this.filtro_valor_hasta,
                    filtro_productos_desde: this.filtro_productos_desde,
                    filtro_productos_hasta: this.filtro_productos_hasta,
                    filtro_peso_desde: this.filtro_peso_desde,
                    filtro_peso_hasta: this.filtro_peso_hasta,
                    filtro_volumen_desde: this.filtro_volumen_desde,
                    filtro_volumen_hasta: this.filtro_volumen_hasta,
                }
                const {data} = await Pedidos.getListarPedidosCambioEstado(params)
                this.pedidos = data.pedidos;
                this.total_pedidos = data.total_pedidos;
                this.total_intentos = data.total_intentos;
                this.total_productos = data.total_productos;
                this.total_valor_pedidos = data.total_valor_pedidos;
                this.total_productos_faltantes = data.total_productos_faltantes;
                if (this.isObjEmpty(this.info_filtros)){
                    this.info_filtros = data.info_filtros;
                }
                this.cargando = false;
            } catch (error){
                this.error_catch(error)
            }
        },

        irFiltro(){
            this.$refs.modalFiltro.toggle();
        },

        determinarHorario(hora){
            if (hora < 12){
                return "Desde las "+hora+":00 a.m";
            }
            if (hora >= 12){
                return "Desde las "+hora+":00 p.m";
            }
            return "";
        },

        irPedido(idPedido){
            this.$refs.modalDetalle.toggle(idPedido)
        },

        isObjEmpty(obj){
            return Object.keys(obj).length === 0;
        },
    }
}
</script>
<style>
.btn-black {
    background: #000000;
    color: #ffffff;
    min-width: 100%;
}
</style>
