import axios from 'axios'

const API = 'pedidos-admin-envios'

const Envios = {
    listarEnviosPedidos(params){
        return axios(`${API}/pedidos`,{params})
    },
    postAsignar(params){
        return axios.post(`${API}/asignar`,params)
    },
    getListarPedidosAsignar(params){
        return axios(`${API}/pedidos_asignar`,{params})
    }
}

export default Envios
