<template>
    <section class="mx-4 pt-3">
        <tabs :tabs="rutas" class="border-bottom" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 192px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            rutas: [
                { titulo: 'Asignados', ruta: 'admin.rutas-entrega-2.pedidos-proceso.asignados' },
                { titulo: 'No asignados', ruta: 'admin.rutas-entrega-2.pedidos-proceso.no-asignados' },
            ]
        }
    }
}
</script>