import axios from 'axios'

const API = 'drivers'

const drivers = {
    getDrivers(){
        return axios(`${API}/get-drivers`)
    },
    crearDrivers(form){
        return axios.post(`${API}/crear-drivers`,form)
    },
    detalleDriver(id_driver){
        return axios(`${API}/${id_driver}/detalle-driver`)
    },
    serviciosDrivers(id_driver,params){
        return axios.get(`${API}/${id_driver}/servicios-driver`,{params})
    },
    editarDriver(id_driver,form){
        return axios.put(`${API}/${id_driver}/editar-driver`,form)
    },
    eliminarDriver(id_driver){
        return axios.delete(`${API}/${id_driver}/eliminar-driver`)
    }
}
export default drivers