<template>
    <section class="mt-4 mx-3">
        <div class="row mx-0">
            <div class="col mr-2 bg-white br-t-8 px-0">
                <div class="row mx-0 align-items-center border-bottom" style="height:56px;">
                    <div class="col-auto">
                        <div class="btn-back px-0 d-middle-center cr-pointer" @click="$router.back()">
                            <i class="icon-left f-14" />
                        </div>
                    </div>
                    <div class="col-auto px-2 text-general f-600 f-22">
                        {{ datos.nombre }}
                    </div>
                    <el-tooltip placement="bottom" content="Editar Driver" effect="light" class="ml-auto">
                        <div class="btn-action border cr-pointer" @click="editarDriver()">
                            <i class="icon-pencil-outline text-general f-20" />
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Eliminar driver" effect="light">
                        <div class="btn-action border mx-2 cr-pointer" @click="eliminarDriver">
                            <i class="icon-delete-outline text-general f-20" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="custom-scroll overflow-auto pt-3 pl-4" style="height:calc(100vh - 137px)">
                    <div class="row mx-0">
                        <img :src="datos.imagen" width="162" height="162" class="obj-cover br-6" />
                        <div class="col px-3">
                            <div class="row mx-0 align-items-center">
                                <i class="icon-truck f-20 text-general" />
                                <div class="col-auto px-2">
                                    <p class="f-15 text-general f-400">
                                        {{ datos.placa || "------" }}
                                    </p>
                                    <p class="f-13 text-general f-300">
                                        Vehículo
                                    </p>
                                </div>
                                <el-tooltip placement="bottom" content="Servicios del vehículo" effect="light">
                                    <div class="btn-action border ml-2 cr-pointer" @click="serviciosVehiculo">
                                        <i class="icon-info-circled-alt f-20 text-general" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <p class="f-15 f-300 text-general mt-3">
                                <span class="f-17 mr-1 f-600">
                                    Número de documento:
                                </span>
                                {{ datos.identificacion }}
                            </p>
                            <p class="f-15 f-300 text-general mt-3">
                                <span class="f-17 mr-1 f-600">
                                    Teléfono celular:
                                </span>
                                {{ datos.telefono }}
                            </p>
                            <p class="f-15 f-300 text-general mt-3">
                                <span class="f-17 mr-1 f-600">
                                    id_cargamos
                                </span>
                                {{ datos.cargamos_id }}
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 align-items-center">
                        <div class="col-auto f-17 f-600 px-0 text-general">
                            Calificación
                        </div>
                        <div class="col-auto ml-3" @click="calificacionDriver">
                            <span class="text-general f-600 f-17">
                                {{ datos.calificacion }}
                            </span>
                            <i class="icon-star text-warning f-20" />
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0">
                        <p class="col-12 f-17 f-600 text-general px-0">
                            Entregas realizadas y programadas
                        </p>
                        <div class="bg-fondo py-3 px-3 text-general br-12 mt-3">
                            <span class="f-15">Última semana</span>
                            <span class="f-18 text-general f-600">
                                {{ datos.entregas.entregas_1_semana || "0" }}
                            </span>
                        </div>
                        <div class="bg-fondo py-3 px-3 text-general br-12 mt-3 ml-3">
                            <span class="f-15">Hoy</span>
                            <span class="f-18 text-general f-600">
                                {{ datos.entregas.entregas_hoy || "0" }}
                            </span>
                        </div>
                        <div class="bg-fondo py-3 px-3 text-general br-12 mt-3 ml-3">
                            <span class="f-15">Mañana</span>
                            <span class="f-18 text-general f-600">
                                {{ datos.entregas.entregas_manana || "0" }}
                            </span>
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0">
                        <p class="col-12 f-17 f-600 text-general px-0">
                            Entregas del último mes
                        </p>
                        <p class="col-12 f-17 f-600 text-general px-0 text-center mt-3">
                            {{ datos.entregas.entregas_mes }} Pedidos <span :class="porcentaje()">{{ datos.entregas.entregas_porcentaje == 1 ? '0' : datos.entregas.entregas_porcentaje }}%</span>
                        </p>
                        <div class="col-12">
                            <echart :options="entregas_ultimos_mes" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col ml-2 bg-white br-t-8 px-0">
                <div class="row mx-0 align-items-center pl-4 border-bottom" style="height:56px;">
                    <div class="col-auto px-2 text-general f-600 f-22">
                        Historial de entregas
                    </div>
                    <div class="d-middle br-20 border px-3 text-general bg-fondo f-14 ml-auto" style="height:28px;">
                        <b class="mr-1">
                            {{ datos.entregas.entregas_1_semana }}
                        </b>
                        en la última semana
                    </div>
                    <div class="d-middle br-20 border px-3 text-general bg-fondo f-14 ml-2" style="height:28px;">
                        <b class="mr-1">
                            {{ datos.entregas.entregas_mes }}
                        </b>
                        en el último mes
                    </div>
                </div>
                <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 137px)">
                    <div class="row mx-0 pl-4">
                        <div class="col-auto px-2 f-18 text-general f-600">
                            Pedidos entregados
                        </div>
                        <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-6 ml-auto">
                            <el-input v-model="buscar" size="small" placeholder="Buscar placa" class="br-20 w-100" />
                        </div>
                    </div>
                    <div class="row mx-0 align-items-center ">
                        <div class="col-12 px-0 mt-3">
                            <el-table
                            :data="historico.filter(h => !buscar || h.placa.toLowerCase().includes(buscar.toLowerCase()))"
                            header-row-class-name="text-general f-14"
                            style="width: 100%"
                            >
                                <el-table-column
                                label="Pedido"
                                width="100"
                                >
                                    <template slot-scope="{row}">
                                        {{ row.id }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="Estado"
                                min-width="80"
                                >
                                    <template slot-scope="{row}">
                                        {{ estadoNombre(row.estado) }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="Vehiculo"
                                min-width="70"
                                >
                                    <template slot-scope="{row}">
                                        {{ row.placa || "------" }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="Valor"
                                min-width="100"
                                >
                                    <template slot-scope="{row}">
                                        {{ convertMoney(row.valor_final) }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="Peso (Kg)"
                                width="100"
                                class="text-center"
                                >
                                    <template slot-scope="{row}">
                                        {{ Math.round(row.total_peso) }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="Volúmen (M3)"
                                width="100"
                                >
                                    <template slot-scope="{row}">
                                        {{ Math.round(row.total_volumen) }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                label="Fecha"
                                min-width="100"
                                >
                                    <template slot-scope="{row}">
                                        {{ formatearFecha(row.entrega_fecha) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center mt-3">
                        <div v-if="longitud" class="px-4 bg-general3 px-2 br-20 text-white f-14 cr-pointer" @click="serviciosDriver()">
                            Ver más
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="serviciosVehiculo" titulo="Servicios del vehículo" tamano="modal-sm" no-aceptar no-cancelar>
            <div v-for="(row,index) in datos.servicios" :key="index" class="row mx-0 align-items-center justify-center mb-3">
                <i class="icon-truck f-22 text-general" />
                <div class="col-auto text-general px-2">
                    {{ row.placa }}
                </div>
                <div class="col-3 text-center text-general f-300">
                    {{ row.cantidad }}
                </div>
            </div>
        </modal>
        <modal-calificacion-driver ref="calificacionDriver" />
        <modal-eliminar ref="modalEliminarDriver" titulo="Eliminar Driver" mensaje="¿Desea eliminar este driver?" @eliminar="deleteDriver()" />
        <modal-crear-driver ref="modalEditarDriver" titulo="Editar Driver" @update="detalleDriver" />
    </section>
</template>

<script>
import Drivers from '~/services/drivers/drivers'
import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);

export default {
    components: {
        modalCalificacionDriver: () => import('./partials/modalCalificacionDriver'),
        modalCrearDriver: () => import('./partials/modalEditarDriver')
    },
    data(){
        return {
            buscar: '',
            entregas_ultimos_mes:{
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        label: {
                            show: false
                        }
                    }
                },
                dataset:{
                    dimensions:['dia', 'count'],
                    source: this.source,
                },
                grid: {
                    bottom: '1%',
                },
                xAxis: {
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#F6F6F6',
                            width: 2
                        }
                    },
                    axisLabel: {
                        show: false,
                        color: '#707070'
                    },
                },
                yAxis: {
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#F6F6F6',
                            width: 2
                        }
                    },
                    axisLabel: {
                        show: true,
                        color: '#707070'
                    },
                    splitLine: { //分割线配置
                        lineStyle: {
                            color: "#AAAAAA56",
                        }
                    },
                },
                series: [
                    {
                        name: 'Cantidad',
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                ]
            },
            datos : {
                entregas: {entregas_1_semana: 0},
                historial: {},
                id: 0,
                telefono: 0,
                cargamos_id: 0,
                foto: '',
                identificacion: 0
            },
            historico: [],
            longitud: 0
        }
    },
    mounted(){
        this.detalleDriver();
        this.serviciosDriver();
    },
    methods: {
        serviciosVehiculo(){
            this.$refs.serviciosVehiculo.toggle();
        },
        calificacionDriver(){
            this.$refs.calificacionDriver.toggle();
        },
        eliminarDriver(){
            this.$refs.modalEliminarDriver.toggle();
        },
        editarDriver(){
            let form = {
                id: this.datos.id,
                nombre: this.datos.nombre,
                cargamos_id: this.datos.cargamos_id,
                identificacion: this.datos.identificacion,
                telefono: this.datos.telefono,
                imagen: this.datos.imagen
            }
            this.$refs.modalEditarDriver.toggle(form);
        },
        async detalleDriver(){
            try {
                let id_driver = this.$route.params.id_driver
                const { data } = await Drivers.detalleDriver(id_driver);
                this.datos = data.data;
                this.entregas_ultimos_mes.dataset.source = [...this.datos.historial]
            } catch (e){
                this.error_catch(e)
            }
            
        },
        async serviciosDriver(){
            try {
                let params = {
                    offset: this.historico.length
                }
                let id_driver = this.$route.params.id_driver
                const { data } = await Drivers.serviciosDrivers(id_driver,params);
                this.historico = [...this.historico, ...data]
                this.longitud = data.length
            } catch (e){
                this.error_catch(e)
            }
        },
        estadoNombre(estado){
            estado = parseInt(estado)
            switch (estado){
            case 1:
                return "Sin confirmar"
            case 2:
                return "Confirmado"
            case 201:
                return "Alistado"
            case 202:
                return "Empacado"
            case 3:
                return "Enviado"
            case 4:
                return "Finalizado"
            case 25:
                return "Rechazado"
            case 26:
                return "Cancelado" 
            case 27:
                return "Cancelado"
            case 31:
                return "En reclamo" 
            case 32:
                return "Reclamo finalizado" 
            default:
                return "Cambios en proceso"
            }
        },
        porcentaje(){
            if(this.datos.entregas.entregas_porcentaje>0) return "ml-2 text-green"
            if(this.datos.entregas.entregas_porcentaje<0) return "ml-2 text-general-red"
        },
        buscarPedidos(){
            this.historico = this.historico.filter(historico => !this.buscar || historico.placa.toLowerCase().includes(this.buscar.toLowerCase()))
        },
        async deleteDriver(){
            try {
                let id_driver = this.$route.params.id_driver
                const { data } = await Drivers.eliminarDriver(id_driver)
                this.notificacion("Éxito","Driver eliminado correctamente","success")
                this.$refs.modalEliminarDriver.toggle();
                this.$router.push({name:'admin.rutas-entrega-2.drivers'})
            } catch (e){
                this.error_catch(e)
            }
        }
        
    }
}
</script>
<style lang="scss" scoped>
.btn-back{
    background-color: #000000 !important;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #FFFFFF;
}
</style>