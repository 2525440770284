import axios from 'axios'

const ENDPOINTS = {
    getListarPedidosCambioEstado(params){
        return axios(`pedidos-cambio-estado/pedidos_ce`,{params})
    },
    getPedidosAsignados(params){
        return axios(`pedidos-asignados/pedidos_asignados`,{params})
    },
    getPedidosAsignarOperador(params){
        return axios(`pedidos-asignados/asignar-listado`, {params})
    },
    getListarPedidosHistorial(params){
        return axios(`pedidos-historial-busqueda/pedidos_historial`,{params})
    },
    getInfoRouting(idPedido){
        return axios(`pedidos-routing/pedido/${idPedido}/detalle`)
    },
    getHorariosCedis(){
        return axios(`pedidos-routing/get-horarios`)
    },
    finalizarMasivo(form){
        return axios.put(`pedidos-routing/finalizar/masivo`,form)
    },
}


export default ENDPOINTS
