<template>
    <section>
        <div class="row mx-0 align-items-center pt-3">
            <div class="col-auto text-general f-600 f-17">
                Drivers
            </div>
            <div class="col-auto ml-auto">
                <div class="btn-filtro border d-middle bg-white br-8 px-2 position-relative" @click="show_filter = !show_filter">
                    <span class="text-general f-14 tres-puntos">{{ filtroNombre }}</span>
                    <i class="icon-swap-vertical-bold-up text-general f-15 ml-auto" />
                    <div v-if="show_filter" class="position-absolute f-14 bg-white br-6 shadow" style="width:164px;left:0;top:36px;z-index:5">
                        <p 
                        v-for="(filter, f) in filter_options" 
                        :key="f" 
                        class="pl-2 filter-option d-middle cr-pointer" 
                        @click="ordenar(filter.id)"
                        >
                            {{ filter.nombre }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto px-2">
                <div class="bg-general cr-pointer text-white br-8 d-middle-center shadow px-5 f-14" style="height:32px;" @click="crearDriver">
                    Crear driver
                </div>
            </div>
            <div class="col-3">
                <el-input v-model="buscar" size="small" class="w-100" placeholder="Buscar" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3">
            <div class="row mx-0">
                <router-link v-for="(driver,index) in datos.filter(data => !buscar || data.nombre.toLowerCase().includes(buscar.toLowerCase()))" :key="index" :to="{name: 'admin.rutas-entrega-2.drivers.ver',params: {id_driver: driver.id} }">
                    <div class="card-driver cr-pointer br-12 bg-white position-relative my-2 mx-2">
                        <div v-if="driver.estado" class="bg-general3 f-15 rounded-circle position-absolute" style="right:8px;top:5px;">
                            <i class="icon-truck text-white" />
                        </div>
                        <img :src="driver.imagen" width="100%" height="162px" class="obj-cover br-t-12" />
                        <p class="pl-2  mt-1 tres-puntos f-16 text-general f-500">{{ driver.nombre }}</p>
                        <p class="pl-2 f-15 tres-puntos text-general"><i class="icon-truck f-18 text-general" />{{ driver.placa || "------" }}</p>
                        <p class="pl-2 f-15 tres-puntos text-general my-1"> 
                            <b>{{ driver.calificacion }}</b> 
                            <i class="icon-star f-18 text-warning" />
                        </p>
                        <div class="row mx-0 pl-2 mt-2">
                            <i class="icon-historial text-general f-20" />
                            <div class="col tres-puntos pl-0 text-general">
                                <el-tooltip class="item" effect="light" content="Último servicio" placement="bottom">
                                    <p class="f-15 tres-puntos">
                                        {{ formatearFecha(driver.ult_envio) }}
                                    </p>
                                </el-tooltip>
                                <p class="f-15 tres-puntos mt-2">
                                    {{ driver.cant_envios }} Servicios
                                </p>
                                <p class="f-13 text-general tres-puntos">Último año</p>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-driver ref="modalCrearDriver" @update="listarDrivers" />
    </section>
</template>

<script>

import Drivers from '~/services/drivers/drivers'
export default {
    components: {
        modalCrearDriver: () => import('./partials/modalCrearDriver')
    },
    data(){
        return{
            buscar: '',
            show_filter: false,
            filter_options: [
                { id: 1, nombre: 'Nombre' },
                { id: 2, nombre: 'Cantidad de pedidos' },
                { id: 3, nombre: 'Servicio más reciente' },
                { id: 4, nombre: 'Calificación' }
            ],
            filter_active: 1,
            datos: []
        }
    },
    computed: {
        filtroNombre(){
            if(this.filter_active == 1)return 'Nombre'
            if(this.filter_active == 2)return 'Cantidad de pedidos'
            if(this.filter_active == 3)return 'Servicio más reciente'
            if(this.filter_active == 4)return 'Calificación'
            return 'Nombre'
        }
    },
    mounted(){
        this.listarDrivers()
    },
    methods: {
        crearDriver(){
            this.$refs.modalCrearDriver.toggle()
        },
        async listarDrivers(){
            try {
                const {data} = await Drivers.getDrivers();
                this.datos = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        ordenar(filter){
            switch (filter){
            case 1:
                this.datos = _.sortBy(this.datos, ['asc','nombre'])
                this.filter_active = 1
                break;
            case 2:
                this.datos.reverse(((a,b) => a.cant_envios - b.cant_envios));
                this.filter_active = 2
                break;
            case 3:
                this.datos.reverse(((a,b) => a.ult_envio - b.ult_envio));
                this.filter_active = 3
                break;
            case 4:
                this.datos.sort(((a,b) => b.calificacion - a.calificacion));
                this.filter_active = 4
                break;
            default:
                this.datos.reverse(((a,b) => a.nombre - b.nombre));
                break;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-filtro{
    width: 164px;
    height: 32px;
}
a{
    text-decoration: none !important;
}
.filter-option{
    height: 26px;
    &:hover{
        background-color: #F6F9FB;
    }
}
.card-driver{
    width: 162px;
    height: 341px;
    box-shadow: 0px 1px 4px #00000014;
}
</style>