<template>
    <section class="pt-4">
        <div class="row mx-0 a-center mb-4">
            <p class="col-auto px-0 text-general f-18 f-500">
                Pedidos asignados al operador logístico
            </p>
            <div class="col-auto d-middle px-0 ml-auto">
                <i class="icon-history text-general" />
                <span class="text-general2 f-12 f-300 ml-2">Nuevo intento</span>
            </div>
            <div class="col-auto d-middle px-0 ml-2">
                <i class="icon-package-variant f-18 text-general-red" />
                <span class="text-general2 f-12 f-300 ml-2">Productos faltantes</span>
            </div>
            <div class="col-auto d-middle px-0 ml-2">
                <i class="icon-truck f-18 text-green" />
                <span class="text-general2 f-12 f-300 ml-2">En entrega</span>
            </div>
            <el-tooltip placement="bottom" content="Filtro" effect="light">
                <div class="btn-action border ml-2 cr-pointer" @click="abrirFiltro">
                    <i class="icon-filter-outline f-20" />
                </div>
            </el-tooltip>
            <!-- <div class="btn-general px-3 f-14 mx-3">
                Entregar
            </div> -->
            <div class="d-flex select-component ml-3">
                <div class="left border-right cr-pointer" :class="componentActive == 'franja' ? 'active' : ''" @click="componentActive = 'franja'">
                    <el-tooltip placement="bottom" content="Ver por franjas" effect="light">
                        <i class="icon-column f-14" />
                    </el-tooltip>
                </div>
                <div class="right cr-pointer" :class="componentActive == 'tabla' ? 'active' : ''" @click="componentActive = 'tabla'">
                    <el-tooltip placement="bottom" content="Ver en tabla" effect="light">
                        <i class="icon-menu f-17" />
                    </el-tooltip>
                </div>
            </div>
        </div>
        <component :is="componentActive" :filtro="filtro" :bandera="bandera" />
        <!-- Partials -->
        <modal-filtro ref="modalFiltro" @filtrar="filtrar($event)" />
    </section>
</template>

<script>
export default {
    components:{
        modalFiltro: () => import('../partials/modalFiltro'),
        franja:  () => import('../components/franjas'),
        tabla:  () => import('../components/tabla'),
    },
    data(){
        return {
            componentActive: 'franja',
            filtro: {},
            bandera: 'asignados',
        }
    },
    methods: {
        abrirFiltro(){
            this.$refs.modalFiltro.toggle(this.bandera);
        },
        filtrar(filtro){
            this.filtro = {...filtro}
        }
    }
}
</script>
<style lang="scss" scoped>
.select-component{
    height: 32px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #CFD7DF;
    .left{
        width: 32px;
        height: 100%;
        border-radius: 8px 0px 0px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        background-image: linear-gradient(to right, #FFFFFF , #F3F5F7);
        &.active{
            background-color: #000000 !important;
            color: #FFFFFF !important;
            background-image: inherit !important;
        }
    }
    .right{
        width: 32px;
        height: 100%;
        border-radius: 0px 8px 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        background-image: linear-gradient(to right, #FFFFFF , #F3F5F7);
        &.active{
            background-color: #000000 !important;
            color: #FFFFFF !important;
            background-image: inherit !important;
        }
    }
}
.text-green{
    color: #02A251;
}
</style>
